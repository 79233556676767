import moment from 'moment-timezone';
import api from '../../../services/workki/Request';
import { setToken, removeToken } from '../../../utils/token';
import * as actions from '../../../store/actions';
import { getRequestStatusActions } from '../../../utils/createRequestActions';

import logger from '../../../utils/logger';

moment.tz.setDefault('Europe/Moscow');
const log = logger('authorization:');

const UTM_FIELDS = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'];

const getUtmData = () => {
  const result = {};
  for (const utm_field of UTM_FIELDS) {
    result[utm_field] = localStorage.getItem(utm_field) || null;
  }
  return result;
};

export const registration = ({ firstName, newEmail, phoneWithCountryCode, token }) => {
  log(`Registration request action, name: ${firstName}, email: ${newEmail}, phone: ${phoneWithCountryCode}`);
  return api.post('/customer/registration', {
    data: { mobile_phone: phoneWithCountryCode, name: firstName, email: newEmail, reCaptcha: token, utm: getUtmData() }
  });
};
export const sendCode = ({ phoneWithCountryCode }) => {
  phoneWithCountryCode = phoneWithCountryCode.replace(/\s+/g, '');
  return api.post('/customer/authorization-code/resend', {
    data: { phone: phoneWithCountryCode }
  });
};
//Переотправка кода при переходе на шаг авторизации по смс
export const resendCode = ({ phoneWithCountryCode }) => {
  phoneWithCountryCode = phoneWithCountryCode.replace(/\s+/g, '');
  return api.post('/customer/authorization-code/resend', {
    data: { phone: phoneWithCountryCode }
  });
};

export const confirmPhoneCode = ({ code, customerId }) => {
  log(`Phone code confirmation request action, code: ${code}, for id: ${customerId}`);
  return api.post(`/customer/registration/confirm-phone/${customerId}`, { data: { code } });
};
export const authByCode = ({ code, customerId }) => {
  return api.post(
    '/auth/code',
    { data: { customer_id: customerId, code: code } },
    [
      () => actions.requestLogin(),
      response => {
        const token = { ...response.data, date_create: moment().format() };
        setToken(token);
        return actions.requestLogin.success(token);
      },
      () => actions.requestLogin.failure('Неправильный логин или пароль!')
    ],
    false
  );
};

export const resendSMSWithPhoneCode = ({ customerId }) => {
  log(`User request to resend SMS with code confirmation, user's id: ${customerId}`);
  return api.post(`/customer/registration/resend-code/${customerId}?send_sms=${1}`);
};

export const confirmEmail = key => {
  log(`Email confirmation request action, key: ${key}`);
  return api.get(`/customer/registration/confirm-email/${key}`);
};

export const confirmPassword = (email, reCaptcha) => {
  log(`Reset forgot password send especially link to "${email}" action`);
  return api.post('/customer/password/reset', { data: { email, reCaptcha } });
};

export const resetPassword = (token, password) => {
  log(`Reset forgot password confirmation action, pass: ${password}`);
  return api.post(`/customer/password/reset/${token}`, { data: { password } });
};

export const loginUser = credentials => {
  const { newEmail, password } = credentials;
  log(`Login user action, email: ${newEmail}, pass: ${password}`);
  // eslint-disable-next-line prettier/prettier
  return api.post(
    '/auth/token',
    { data: { username: newEmail, password } },
    [
      () => actions.requestLogin(),
      response => {
        const token = { ...response.data, date_create: moment().format() };
        setToken(token);
        return actions.requestLogin.success(token);
      },
      () => actions.requestLogin.failure('Неправильный логин или пароль!')
    ],
    false
  );
};

export const logoutUser = () => {
  log('Logout user action');
  removeToken();
  return dispatch => dispatch(actions.logout());
};

// eslint-disable-next-line no-shadow
export const dispatchChangePassword = (newPassword, confirmPassword, currentPassword) => {
  log(
    `Change password user action, current: ${currentPassword}, new: ${newPassword}, confirmation: ${confirmPassword}`
  );
  return api.put(
    'customer/profile',
    { data: { newPassword, confirmPassword, currentPassword } },
    getRequestStatusActions(actions.changePassword),
    false
  );
};

export const registrationFailed = ({ firstName, newEmail, phoneWithCountryCode }) => {
  log(`Failed Registration request action, name: ${firstName}, email: ${newEmail}, phone: ${phoneWithCountryCode}`);
  return api.post('/customer/registration/request-help', {
    data: { phone: phoneWithCountryCode, name: firstName, email: newEmail }
  });
};
