import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import StepBack from '../shared/ui/components/StepBack';
import styled from 'styled-components';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { screenRessolutions } from '../shared/ui/constants/screenResolutions.constants';
import PageLoader from '../components/PageLoader';
import { UploadFile } from '../shared/ui/icons/core/UploadFile';
import { downloadNotificationFile, getAllNotifications } from '../services/workki/NotificationActions';
import saveAs from 'file-saver';
import moment from 'moment';

const UserDataWidthWrapper = styled.div`
  margin: 0;
  padding: 20px 0;
  width: 344px;
  box-sizing: content-box;

  @media screen and (max-width: ${screenRessolutions.tablet}px) {
    max-width: 328px;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 8px;
`;

const YearSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const YearTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #24272a;
`;

const NotificationCard = styled.div`
  box-shadow: 0px 2px 12px 0px #00000014;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  background: #fff;
`;

const NotificationCardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const NotificationCardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationCardDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #8f9295;
`;

const NotificationCardDownload = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;

const NotificationCardDownloadText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #222;
`;

const mapDispatchToProps = dispatch => ({
  getNotifications: page => dispatch(getAllNotifications(page)),
  downloadNotificationFile: id => dispatch(downloadNotificationFile(id))
});

const NotificationPage = ({ getNotifications, downloadNotificationFile, isLoading }) => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const observer = useRef();
  const history = useHistory();

  const loadNotifications = useCallback(
    async page => {
      try {
        setIsFetching(true);
        const response = await getNotifications(page);
        setNotifications(prev => [...prev, ...response.data.files]);
        setTotalPages(response.data.total_pages);
      } catch (error) {
        console.error('Ошибка при загрузке уведомлений:', error);
      } finally {
        setIsFetching(false);
      }
    },
    [getNotifications]
  );

  useEffect(() => {
    loadNotifications(1);
  }, [loadNotifications]);

  const lastElementRef = useCallback(
    node => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && currentPage < totalPages) {
          setCurrentPage(prev => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isFetching, currentPage, totalPages]
  );

  useEffect(() => {
    if (currentPage > totalPages) return;
    if (currentPage > 1) {
      loadNotifications(currentPage);
    }
  }, [currentPage]);

  const groupedNotifications = notifications.reduce((acc, notification) => {
    const year = new Date(notification.created_at).getFullYear();
    if (!acc[year]) acc[year] = [];
    acc[year].push(notification);
    return acc;
  }, {});

  const handleDownload = async (id, filename) => {
    try {
      const response = await downloadNotificationFile(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, filename);
    } catch (error) {
      console.error('Ошибка при скачивании файла:', error);
    }
  };

  return (
    <PrimaryLayout>
      <main className='main'>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            <UserDataWidthWrapper>
              <StepBack
                onClick={() => history.push('/profile')}
                title={'Все уведомления'}
                style={{ marginBottom: '8px' }}
              />
              <PageWrapper>
                {Object.keys(groupedNotifications).map(year => (
                  <YearSection key={year}>
                    <YearTitle>{year}</YearTitle>
                    {groupedNotifications[year].map((notification, index) => (
                      <NotificationCard
                        key={notification.id}
                        ref={index === notifications.length - 1 ? lastElementRef : null}
                      >
                        <NotificationCardTitle>{notification.name}</NotificationCardTitle>
                        <NotificationCardBottom>
                          <NotificationCardDownload
                            onClick={() => handleDownload(notification.id, notification.filename)}
                          >
                            <UploadFile width={14} height={14} spacing={'0px'} defaultColor={'#222'} />
                            <NotificationCardDownloadText>{notification.filename}</NotificationCardDownloadText>
                          </NotificationCardDownload>
                          <NotificationCardDate>
                            {moment(notification.created_at).format('DD.MM.YYYY')}
                          </NotificationCardDate>
                        </NotificationCardBottom>
                      </NotificationCard>
                    ))}
                  </YearSection>
                ))}
                {isFetching && <PageLoader />}
              </PageWrapper>
            </UserDataWidthWrapper>
          </>
        )}
      </main>
    </PrimaryLayout>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(NotificationPage);
