import * as actions from '../../store/actions';
import api from './Request';
import { getRequestStatusActions } from '../../utils/createRequestActions';

export const getNotifications = () => {
  return api.get('/customer/notifications/relevant', getRequestStatusActions(actions.requestNotifications));
};

export const getAllNotifications = page => {
  return api.get(`/customer/notifications?page=${page}`, getRequestStatusActions(actions.requestNotifications));
};

export const downloadNotificationFile = id => {
  return api.get(`/customer/notifications/${id}/download`, {
    responseType: 'blob',
    ...getRequestStatusActions(actions.requestNotifications)
  });
};
