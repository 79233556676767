import React from 'react';
import RegistrationFailedStep from '../../../widgets/AuthorizationWidgets/AuthorizationSteps/RegistrationFailedStep';
import PersonalDataStep from '../../../widgets/AuthorizationWidgets/AuthorizationSteps/PersonalDataStep';
import RegistrationCompleteStep from '../../../widgets/AuthorizationWidgets/AuthorizationSteps/RegistrationCompleteStep';
import ChangePasswordStep from '../../../widgets/AuthorizationWidgets/AuthorizationSteps/ChangePasswordStep';
import EmailAuthStep from '../../../widgets/AuthorizationWidgets/AuthorizationSteps/EmailAuthStep';
import PhonePrimaryStep from '../../../widgets/AuthorizationWidgets/AuthorizationSteps/PhonePrimaryStep';
const StepForm = ({
  stepType,
  handleRegisterSubmit,
  handleAuthSubmit,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  handleClickShowPassword,
  handlePolicyChange,
  setIsForgetPassword,
  handlePhoneAuthClick,
  handleTriggerCall,
  errors,
  setTimer,
  RESEND_INTERVAL,
  formData,
  filled,
  isFocused,
  showPassword,
  isPending,
  policyChecked,
  disableButton,
  setRegistrationStep,
  handleInstantAuth,
  divider,
  setLoadingStatus,
  handleResetPassword,
  handleFailedRegisterSubmit
}) => {
  switch (stepType) {
    case 'personalData':
      return (
        <PersonalDataStep
          handleRegisterSubmit={handleRegisterSubmit}
          handleInputChange={handleInputChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleEraseValue={handleEraseValue}
          handlePolicyChange={handlePolicyChange}
          errors={errors}
          formData={formData}
          filled={filled}
          isFocused={isFocused}
          isPending={isPending}
          policyChecked={policyChecked}
          disableButton={disableButton}
        />
      );
    case 'registrationFailed':
      return (
        <RegistrationFailedStep
          handleInputChange={handleInputChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleEraseValue={handleEraseValue}
          errors={errors}
          formData={formData}
          handleFailedRegisterSubmit={handleFailedRegisterSubmit}
          handlePolicyChange={handlePolicyChange}
          policyChecked={policyChecked}
          filled={filled}
          isFocused={isFocused}
          isPending={isPending}
          disableButton={disableButton}
        />
      );
    case 'registrationComplete':
      return (
        <RegistrationCompleteStep
          handleAuthSubmit={handleAuthSubmit}
          handleInputChange={handleInputChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleEraseValue={handleEraseValue}
          handleClickShowPassword={handleClickShowPassword}
          errors={errors}
          formData={formData}
          filled={filled}
          isFocused={isFocused}
          showPassword={showPassword}
          isPending={isPending}
          disableButton={false}
          handleInstantAuth={handleInstantAuth}
          setLoadingStatus={setLoadingStatus}
        />
      );
    case 'changePassword':
      return (
        <ChangePasswordStep
          handleInputChange={handleInputChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleEraseValue={handleEraseValue}
          handleClickShowPassword={handleClickShowPassword}
          errors={errors}
          formData={formData}
          filled={filled}
          isFocused={isFocused}
          showPassword={showPassword}
          isPending={isPending}
          disableButton={disableButton}
          handleResetPassword={handleResetPassword}
        />
      );
    case 'emailAuth':
      return (
        <EmailAuthStep
          handleAuthSubmit={handleAuthSubmit}
          handleInputChange={handleInputChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleEraseValue={handleEraseValue}
          handleClickShowPassword={handleClickShowPassword}
          errors={errors}
          formData={formData}
          setRegistrationStep={setRegistrationStep}
          filled={filled}
          isFocused={isFocused}
          showPassword={showPassword}
          isPending={isPending}
          disableButton={disableButton}
          setIsForgetPassword={setIsForgetPassword}
          handlePhoneAuthClick={handlePhoneAuthClick}
          divider={divider}
        />
      );
    case 'phoneAuthPrimary':
      return (
        <PhonePrimaryStep
          handleAuthSubmit={handleAuthSubmit}
          handleInputChange={handleInputChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleEraseValue={handleEraseValue}
          handleClickShowPassword={handleClickShowPassword}
          errors={errors}
          formData={formData}
          setRegistrationStep={setRegistrationStep}
          filled={filled}
          isFocused={isFocused}
          showPassword={showPassword}
          isPending={isPending}
          disableButton={disableButton}
          setIsForgetPassword={setIsForgetPassword}
          handlePhoneAuthClick={handlePhoneAuthClick}
          divider={divider}
          handleTriggerCall={handleTriggerCall}
          setTimer={setTimer}
          RESEND_INTERVAL={RESEND_INTERVAL}
        />
      );
    default:
      return null;
  }
};

export default StepForm;
