import React, { useEffect, useRef, useState } from 'react';
import { colors, screenRessolutions } from '../../../../shared/ui/constants/styleConstants';
import { ArrowBack } from '../../../../shared/ui/icons/core/ArrowBack';
import {
  AddressFieldBlock,
  AlertWrapper,
  CheckboxBlock,
  FormContent,
  FormGroupTitle,
  LegalEntityDataBlock,
  LegalEntityInfo,
  LegalEntityInfoWrapper,
  LegalEntityTitle,
  StepLabel,
  TabsWrapper
} from '../../PrefillProfile/model/PrefillProfileComponent';
import styled from 'styled-components';

import { TabsComponent } from '../../../../shared/ui/components/Tabs/Tabs';
import { InfoAlert } from '../../../../shared/ui/components/Profile/InfoAlert';
import { CustomTextField } from '../../../../shared/ui/components/Field/CustomTextField';
import { CheckboxComponent } from '../../../../shared/ui/components/Сheckbox';
import { FormControl } from '@mui/material';
import { PhoneMask } from '../../../../shared/ui/icons/core/PhoneMask';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector } from '../../../../store/selectors/DefaultSelectors';
import { StepButton } from '../../../../shared/ui/components/StepButton';
import { Hints } from '../../../../shared/ui/components/Profile/Hints';
import { getBinaryFile, getCustomerCompanyData, getCustomerFiles } from '../../../../services/workki/CustomerActions';
import { useDebounce } from '../../../../shared/utils/hooks/useDebounce';
import { receiveAddressHints } from '../../PrefillProfile/api/ProfileActions';
import * as yup from 'yup';
import { formatPhoneNumber } from '../../../../shared/utils/serviceUtils/validationUtils';
import { changeLegalEntityAddress, changeLegalEntityContacts } from '../../../../services/workki/PaymentProfileActions';
import { FILE_TYPE_ID } from '../../../../constants';
import { ChangeCredentialsDialog } from '../../../../widgets/ProfileWidgets/Employees/ChangeCredentialsDialog';
import { GuestPassSuccessDialog } from '../../../../widgets/Services/GuestPass/GuestPassSuccessDialog';

export const LabelWrapper = styled.div`
  grid-area: label;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    width: auto;
  }
`;

export const PrefillForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: ${props => props.position};
  overflow: auto;
  @media (min-width: 320px) {
    width: 100%;
    padding: 16px;
    height: calc(100vh - 100px);
    overflow: auto;
  }
  @media (min-width: 768px) {
    min-width: 340px;
    max-width: 634px;
  }
  @media (min-width: 1366px) {
    min-width: 416px;
    max-width: 634px;
  }
  @media (min-width: 1920px) {
    width: 100%;
  }
`;

export const PaymentProfileComponent = () => {
  const customer = useSelector(customerSelector);
  const dispatch = useDispatch();
  const [isNoPatronymic, setIsNoPatronymic] = useState(false);
  const [isMatchingAdress, setIsMatchingAdress] = useState(false);
  const [legalEntityData, setLegalEntityData] = useState(null);
  const [innValue, setInnValue] = useState(`${customer.individual.inn}`);
  const [fullAddress, setFullAddress] = useState(null);
  const [addressHintsData, setAddressHintsData] = useState([]);
  const [inn, setInn] = useState('');
  const [address, setAddress] = useState('');
  const debouncedAddress = useDebounce(address, 1000);
  const [addressAnchor, setAddressAnchor] = useState(null);
  const [inputWidth, setInputWidth] = useState(50);
  const addressOpen = Boolean(addressAnchor);
  const inputRef = useRef(null);
  const [isDataButtonActive, setIsDataButtonActive] = useState(false);
  const [isAddressButtonActive, setIsAddressButtonActive] = useState(false);
  const [passportPhotoUrl, setPassportPhotoUrl] = useState('');
  const [openDialog, setOpenDialog] = useState({ credentialChange: false, success: false });

  const fetchPassportFileId = async () => {
    try {
      const files = await dispatch(getCustomerFiles());
      const passportFile = files.data.find(file => file.type_id === FILE_TYPE_ID.PASSPORT);

      if (passportFile) {
        return passportFile.id;
      }
    } catch (error) {
      console.error('Ошибка при получении списка файлов:', error);
      throw error;
    }
  };

  const fetchPassportPhoto = async fileId => {
    try {
      const response = await dispatch(getBinaryFile(fileId));
      const blobUrl = URL.createObjectURL(response.data);
      console.log(blobUrl);
      return blobUrl;
    } catch (error) {
      console.error('Ошибка при получении фото паспорта:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fileId = await fetchPassportFileId();
        if (fileId) {
          const photoUrl = await fetchPassportPhoto(fileId);
          setPassportPhotoUrl(photoUrl);
        }
      } catch (error) {
        console.error('Error fetching passport photo:', error);
      }
    };

    fetchData();
  }, []);

  const changeEntityAddress = data => dispatch(changeLegalEntityAddress(data));
  const changeEntityContacts = data => dispatch(changeLegalEntityContacts(data));

  const [filled, setFilled] = useState({
    address: false,
    lastName: false,
    firstName: false,
    patronymic: false,
    position: false,
    email: false,
    phone: false
  });

  const [isFocused, setIsFocused] = useState({
    address: false,
    lastName: false,
    firstName: false,
    patronymic: false,
    position: false,
    email: false,
    phone: false
  });

  const [errors, setErrors] = useState({
    address: '',
    lastName: '',
    firstName: '',
    patronymic: '',
    position: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (customer && customer.individual) {
      const { city, street, house, block, office, index } = customer.individual;

      const fullAddress = [
        index && `${index}`,
        city && `г ${city}`,
        street && `ул ${street}`,
        house && `д ${house}`,
        block && `${block}`,
        office && `оф ${office}`
      ]
        .filter(Boolean)
        .join(', ');

      setFormData(prevState => ({
        ...prevState,
        address: fullAddress || prevState.address
      }));
    }
  }, [customer]);

  const initialAddress =
    customer && customer.individual
      ? [
          customer.individual.index && `${customer.individual.index}`,
          customer.individual.city && `г ${customer.individual.city}`,
          customer.individual.street && `ул ${customer.individual.street}`,
          customer.individual.house && `д ${customer.individual.house}`,
          customer.individual.block && `${customer.individual.block}`,
          customer.individual.office && `оф ${customer.individual.office}`
        ]
          .filter(Boolean)
          .join(', ')
      : '';

  const [formData, setFormData] = useState({
    address: initialAddress,
    lastName:
      customer && customer.individual && customer.individual.director_last_name
        ? customer.individual.director_last_name
        : '',
    firstName:
      customer && customer.individual && customer.individual.director_first_name
        ? customer.individual.director_first_name
        : '',
    patronymic:
      customer && customer.individual && customer.individual.director_middle_name
        ? customer.individual.director_middle_name
        : '',
    position:
      customer && customer.individual && customer.individual.director_post ? customer.individual.director_post : '',
    email:
      customer && customer.individual && customer.individual.director_email ? customer.individual.director_email : '',

    phone:
      customer && customer.individual && customer.individual.director_phone ? customer.individual.director_phone : ''
  });

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    const cleanField = !(name === 'inn' || name === 'address' || name === 'position');
    const inputValue = cleanField ? value.replace(/\s+/g, '') : value;
    const cleanValue = name === 'phone' ? value.replace(/\D/g, '') : inputValue;
    if (name === 'inn') {
      if (value !== '') {
        setInn(cleanValue);
      }
    }
    if (name === 'address') {
      if (value !== '') {
        setAddressAnchor(evt.currentTarget);
        setAddress(cleanValue);
      }
    }

    updateFilledState(name, cleanValue);
    updateFormData(name, cleanValue);
  };

  const isDataButtonEnabled = () => {
    if (customer.is_company) {
      const filteredData = getFilteredData();
      return (
        filteredData.firstName.trim() !== '' &&
        filteredData.lastName.trim() !== '' &&
        (!isNoPatronymic ? filteredData.patronymic.trim() !== '' : true) &&
        filteredData.phone.trim() !== '' &&
        filteredData.email.trim() !== '' &&
        filteredData.position.trim() !== ''
      );
    }
  };

  const isAddressButtonEnabled = () => {
    if (customer.is_company) {
      const filteredData = getFilteredData();
      return filteredData.address.trim() !== '';
    }
  };

  const getFilteredData = () => {
    if (customer.is_legal_entity || customer.is_individual_entrepreneur) {
      return {
        address: formData.address,
        lastName: formData.lastName,
        firstName: formData.firstName,
        patronymic: isNoPatronymic ? '' : formData.patronymic,
        position: formData.position,
        phone: formData.phone,
        email: formData.email
      };
    }
  };

  const updateFilledState = (name, inputValue) => {
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: inputValue !== ''
    }));
  };

  useEffect(() => {
    setIsDataButtonActive(isDataButtonEnabled());
  }, [formData, isNoPatronymic]);

  useEffect(() => {
    setIsAddressButtonActive(isAddressButtonEnabled());
  }, [formData, isMatchingAdress]);

  const updateFormData = (name, inputValue) => {
    if (name === 'email') {
      const emailSchema = yup
        .string()
        .email('Некорректный email')
        .required('Email обязателен');
      emailSchema
        .validate(inputValue)
        .then(() => {
          setErrors(prevState => ({
            ...prevState,
            email: ''
          }));
        })
        .catch(err => {
          setErrors(prevState => ({
            ...prevState,
            email: err.errors[0]
          }));
        });
    }

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: name === 'phone' ? formatPhoneNumber(inputValue, setErrors) : inputValue
      };
    });
  };

  const handleEraseValue = fieldName => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
    if (fieldName === 'address') {
      setAddressAnchor(null);
    }
  };

  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };
  const handleBlur = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
    handleClose(fieldName);
  };

  const handleClose = type => {
    if (type === 'address') {
      setAddressAnchor(null);
    }
  };

  const handleSubmitData = () => {
    try {
      const formattedData = formatContactDataForBackend(formData, isNoPatronymic);
      console.log('Submitting contact data:', formattedData);
      changeEntityContacts(formattedData);
    } catch (err) {
      console.error('Error while submitting contact data:', err);
    }
  };

  const handleSubmitAddress = () => {
    try {
      const formattedAddress = formatAddressForBackend(fullAddress);
      if (!formattedAddress) {
        console.error('Полный адрес отсутствует или некорректен');
        return;
      }
      console.log('Submitting address:', formattedAddress);
      changeEntityAddress(formattedAddress);
    } catch (err) {
      console.error('Error while submitting address:', err);
    }
  };

  const formatAddressForBackend = fullAddress => {
    if (!fullAddress) return null;

    return {
      city: fullAddress.city || '',
      street: fullAddress.street || '',
      house: fullAddress.house || '',
      block: fullAddress.block || '',
      office: fullAddress.flat || '',
      index: fullAddress.postal_code || ''
    };
  };

  const formatContactDataForBackend = (formData, isNoPatronymic) => {
    if (customer.is_company) {
      return {
        director_first_name: formData.firstName.trim(),
        director_last_name: formData.lastName.trim(),
        director_middle_name: isNoPatronymic ? null : formData.patronymic.trim(),
        director_email: formData.email.trim(),
        director_phone: formData.phone.trim(),
        director_post: formData.position.trim()
      };
    }
  };

  const [tabValue, setTabValue] = useState('contacts');

  const handleTabChange = (event, newValue) => {
    setAddressAnchor(null);
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(getCustomerCompanyData(innValue)).then(response => setLegalEntityData(response.data));

    const type = tabValue;
    setFormData(prevState => ({
      ...prevState,
      inn: { ...prevState.inn, [type]: innValue }
    }));
  }, [innValue]);

  useEffect(() => {
    if (debouncedAddress !== '') {
      dispatch(receiveAddressHints(debouncedAddress)).then(response => setAddressHintsData(response.data));
    }
  }, [debouncedAddress]);

  useEffect(() => {
    if (inputRef.current) {
      const width = inputRef.current.getBoundingClientRect().width;
      setInputWidth(+width + 50);
    }
  }, [formData]);
  console.log(customer);

  const checkAddressButtonState = () => {
    return formData.address.trim() !== initialAddress.trim() && formData.address.trim() !== '';
  };

  useEffect(() => {
    setIsAddressButtonActive(checkAddressButtonState());
  }, [formData.address, initialAddress, tabValue]);

  return (
    <>
      <PrefillForm>
        <LabelWrapper>
          <StepLabel reservation={'reservation'} position='center' onClick={() => (window.location.href = '/profile')}>
            <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
            <p style={{ margin: 'auto' }}>Платежный профиль</p>
          </StepLabel>
        </LabelWrapper>
        {customer.is_company ? (
          <>
            <TabsWrapper>
              <TabsComponent
                tabsContainerStyle={{ width: '100%', height: '40px' }}
                tabStyle={{ height: '32px', maxWidth: '294px' }}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                tabs={[{ label: 'Контакты', value: 'contacts' }, { label: 'Реквизиты', value: 'credits' }]}
              />
            </TabsWrapper>
            {tabValue === 'contacts' && (
              <>
                {(customer.is_legal_entity || customer.is_individual_entrepreneur) && (
                  <FormContent>
                    <FormGroupTitle>
                      <p>ФИО и должность руководителя</p>
                    </FormGroupTitle>
                    <div className='payment-form-names-row'>
                      <CustomTextField
                        error={errors.lastName}
                        type='text'
                        name='lastName'
                        id='outlined-helperText'
                        label='Фамилия'
                        variant='filled'
                        value={formData.lastName}
                        onFocus={() => handleFocus('lastName')}
                        onBlur={() => handleBlur('lastName')}
                        onChange={handleInputChange}
                        helperText={errors.lastName ? errors.lastName : ''}
                        endAdornmentProps={{
                          filled: filled.lastName,
                          isFocused: isFocused.lastName,
                          handleEraseValue: name => handleEraseValue(name)
                        }}
                        autoFocus={true}
                      />
                      <CustomTextField
                        error={errors.firstName}
                        type='text'
                        name='firstName'
                        id='outlined-helperText'
                        label='Имя'
                        variant='filled'
                        value={formData.firstName}
                        onFocus={() => handleFocus('firstName')}
                        onBlur={() => handleBlur('firstName')}
                        onChange={handleInputChange}
                        helperText={errors.firstName ? errors.firstName : ''}
                        endAdornmentProps={{
                          filled: filled.firstName,
                          isFocused: isFocused.firstName,
                          handleEraseValue: name => handleEraseValue(name)
                        }}
                        autoFocus={false}
                      />
                      {!isNoPatronymic && (
                        <CustomTextField
                          error={errors.patronymic}
                          type='text'
                          name='patronymic'
                          id='outlined-helperText'
                          label='Отчество'
                          variant='filled'
                          value={formData.patronymic}
                          onFocus={() => handleFocus('patronymic')}
                          onBlur={() => handleBlur('patronymic')}
                          onChange={handleInputChange}
                          helperText={errors.patronymic ? errors.patronymic : ''}
                          endAdornmentProps={{
                            filled: filled.patronymic,
                            isFocused: isFocused.patronymic,
                            handleEraseValue: name => handleEraseValue(name)
                          }}
                          autoFocus={false}
                        />
                      )}
                    </div>

                    <CheckboxBlock>
                      <CheckboxComponent
                        checked={isNoPatronymic}
                        onChange={() => setIsNoPatronymic(!isNoPatronymic)}
                        checkedIcon={<CheckBoxRoundedIcon />}
                      />
                      <p>Нет отчества</p>
                    </CheckboxBlock>
                    <CustomTextField
                      error={errors.position}
                      type='text'
                      name='position'
                      id='outlined-helperText'
                      label='Должность'
                      variant='filled'
                      value={formData.position}
                      onFocus={() => handleFocus('position')}
                      onBlur={() => handleBlur('position')}
                      onChange={handleInputChange}
                      helperText={errors.position ? errors.position : ''}
                      endAdornmentProps={{
                        filled: filled.position,
                        isFocused: isFocused.position,
                        handleEraseValue: name => handleEraseValue(name)
                      }}
                      autoFocus={false}
                    />
                    <FormGroupTitle>
                      <p>Контакты руководителя</p>
                    </FormGroupTitle>
                    <CustomTextField
                      error={''}
                      id='outlined-helperText'
                      label='Телефон'
                      name='phone'
                      helperText={errors.phone ? errors.phone : ''}
                      variant='filled'
                      type='tel'
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder={'999 999-99-99'}
                      onFocus={() => handleFocus('phone')}
                      onBlur={() => handleBlur('phone')}
                      endAdornmentProps={{
                        filled: filled.phone,
                        isFocused: isFocused.phone,
                        handleEraseValue: name => handleEraseValue(name)
                      }}
                      startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
                      autoFocus={false}
                    />
                    <CustomTextField
                      error={errors.email}
                      type='email'
                      name='email'
                      id='outlined-helperText'
                      label='E-mail'
                      variant='filled'
                      value={formData.email}
                      onFocus={() => handleFocus('email')}
                      onBlur={() => handleBlur('email')}
                      onChange={handleInputChange}
                      helperText={errors.email ? errors.email : ''}
                      endAdornmentProps={{
                        filled: filled.email,
                        isFocused: isFocused.email,
                        handleEraseValue: name => handleEraseValue(name)
                      }}
                      autoFocus={false}
                    />
                    <FormControl fullWidth={true} style={{ padding: '16px 0' }}>
                      <StepButton
                        type='filled'
                        filledType='black'
                        heightOverride={'56px'}
                        title={'Сохранить изменения'}
                        handleAction={handleSubmitData}
                        disabled={!isDataButtonActive || Object.values(errors).some(error => error !== '')}
                      />
                    </FormControl>
                  </FormContent>
                )}
              </>
            )}
            {tabValue === 'credits' && (
              <PrefillForm position={'flex-start'} style={{ padding: 0 }}>
                <FormContent>
                  {legalEntityData && legalEntityData.name && (
                    <LegalEntityDataBlock>
                      {customer.is_individual_entrepreneur ? (
                        <LegalEntityInfo>
                          <span style={{ color: '#24272a' }}>{legalEntityData.name.short}</span>
                        </LegalEntityInfo>
                      ) : (
                        <LegalEntityTitle>
                          <p>Наименование:</p>
                          <p>{legalEntityData.name.short}</p>
                        </LegalEntityTitle>
                      )}
                      <LegalEntityInfoWrapper>
                        <LegalEntityInfo>
                          <span>ИНН: </span>
                          <span>{innValue}</span>
                        </LegalEntityInfo>
                        {legalEntityData.requisites.kpp !== '' && (
                          <LegalEntityInfo>
                            <span>КПП: </span>
                            <span>{legalEntityData.requisites.kpp}</span>
                          </LegalEntityInfo>
                        )}
                        {legalEntityData.requisites.ogrn !== '' && (
                          <LegalEntityInfo>
                            <span>{customer.is_individual_entrepreneur ? 'ОГРНИП: ' : 'ОГРН: '}</span>
                            <span>{legalEntityData.requisites.ogrn}</span>
                          </LegalEntityInfo>
                        )}
                      </LegalEntityInfoWrapper>
                      <LegalEntityTitle>
                        <p>{customer.is_legal_entity ? 'Юридический адрес: ' : 'Адрес регистрации '}</p>
                        <p>{legalEntityData.address.full_address}</p>
                      </LegalEntityTitle>
                    </LegalEntityDataBlock>
                  )}
                  {customer.is_legal_entity && (
                    <>
                      <CheckboxBlock>
                        <CheckboxComponent
                          checked={isMatchingAdress}
                          onChange={() => setIsMatchingAdress(!isMatchingAdress)}
                          name='addressCheckbox'
                          checkedIcon={<CheckBoxRoundedIcon />}
                        />
                        <p>Совпадает с юридическим</p>
                      </CheckboxBlock>
                      {!isMatchingAdress && (
                        <FormControl fullWidth={true}>
                          <AddressFieldBlock>
                            <CustomTextField
                              error={errors.address ? errors.address : ''}
                              type='text'
                              name='address'
                              id='outlined-helperText'
                              label='Фактический адрес'
                              variant='filled'
                              value={formData.address}
                              onChange={handleInputChange}
                              onBlur={() => handleBlur('address')}
                              autoFocus={false}
                              inputRef={inputRef}
                            />
                            <Hints
                              open={addressOpen}
                              formData={formData}
                              anchorEl={addressAnchor}
                              inputWidth={inputWidth}
                              addressHintsData={addressHintsData}
                              type={'address'}
                              errors={errors.address ? errors.address : ''}
                              setErrors={value =>
                                setErrors(prevState => ({
                                  ...prevState,
                                  address: value
                                }))
                              }
                              setFullAddress={value => setFullAddress(value)}
                              setInnValue={address => {
                                setFormData(prevState => ({
                                  ...prevState,
                                  address: address
                                }));
                              }}
                            />
                          </AddressFieldBlock>
                        </FormControl>
                      )}
                    </>
                  )}
                  {!isMatchingAdress && customer.is_legal_entity && (
                    <StepButton
                      type='filled'
                      filledType='black'
                      heightOverride={'56px'}
                      title={'Сохранить изменения'}
                      handleAction={handleSubmitAddress}
                      disabled={!isAddressButtonActive}
                    />
                  )}
                  {customer.is_legal_entity && (
                    <InfoAlert
                      title={'Название, реквизиты и юридический адрес могут быть изменены только менеджером Workki'}
                      link={'Оставить заявку'}
                      type={'info'}
                      handleAction={() => {
                        setOpenDialog(prevState => ({ ...prevState, credentialChange: true }));
                      }}
                    />
                  )}
                  {customer.is_individual_entrepreneur && (
                    <InfoAlert
                      title={'Реквизиты и адрес регистрации могут быть изменены только менеджером Workki'}
                      link={'Оставить заявку'}
                      type={'info'}
                      handleAction={() => {
                        setOpenDialog(prevState => ({ ...prevState, credentialChange: true }));
                      }}
                    />
                  )}
                </FormContent>
              </PrefillForm>
            )}
          </>
        ) : (
          <PrefillForm>
            <FormContent>
              <LegalEntityDataBlock>
                <LegalEntityTitle>
                  <p>ФИО:</p>
                  <p>{`${customer.individual.last_name} ${customer.individual.first_name} ${customer.individual.middle_name}`}</p>
                </LegalEntityTitle>

                {/* пока под вопросом */}

                {/* <LegalEntityTitle>
                    <p>Адрес регистрации:</p>
                    <p>{legalEntityData.address.full_address}</p>
                  </LegalEntityTitle>
                  <LegalEntityTitle>
                    <p>Серия и номер: </p>
                    <p>{`${customer.individual.passport_series || '39 57'} ${customer.individual.passport_number ||
                      '462005'}`}</p>
                  </LegalEntityTitle>
                  <LegalEntityTitle>
                    <p>Выдан: </p>
                    <p>ГУ МВД России по Свердловской области</p>
                  </LegalEntityTitle>
                  <LegalEntityTitle>
                    <p>Дата выдачи:</p>
                    <p>01.01.2024</p>
                  </LegalEntityTitle> */}
                <img
                  src={passportPhotoUrl || ''}
                  alt='Фото паспорта'
                  style={{
                    width: '120px',
                    height: '160px',
                    marginTop: '8px',
                    marginBottom: '16px',
                    objectFit: 'cover'
                  }}
                />
              </LegalEntityDataBlock>
              <InfoAlert
                title={'Данные платежного профиля могут быть изменены только менеджером Workki'}
                link={'Оставить заявку'}
                type={'info'}
                handleAction={() => {
                  setOpenDialog(prevState => ({ ...prevState, credentialChange: true }));
                }}
              />
            </FormContent>
          </PrefillForm>
        )}
        {openDialog.success && (
          <GuestPassSuccessDialog
            dialogType='changePaymentCredentialsSuccess'
            title='Заявка отправлена'
            handleClose={() => {
              setOpenDialog(prevState => ({ ...prevState, success: false }));
            }}
          />
        )}
        {openDialog.credentialChange && (
          <ChangeCredentialsDialog
            title='Заявка на изменение данных'
            dialogType='changePaymentCredentials'
            handleClose={() => {
              setOpenDialog(prevState => ({ ...prevState, credentialChange: false }));
            }}
            handleSubmit={() => {
              setOpenDialog(prevState => ({ ...prevState, credentialChange: false, success: true }));
            }}
          />
        )}
      </PrefillForm>
    </>
  );
};
