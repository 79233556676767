import { createSelector } from 'redux-orm';
import { has, isNil, last, sortBy } from 'lodash';
import moment from 'moment';
import orm from '../models/orm';
import { FILE_TYPE_ID, CUSTOMER_FILE_STATUS } from '../../constants';

const dbStateSelector = state => state.entities;
const parkingType = (state, props) => props.parkingType;

export const customerSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const customer = session.Customer.first();
    if (customer !== undefined) {
      return Object.assign({}, customer.ref);
    }
    return;
  }
);

export const customerAvatarFileSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const publicFiles = session.CustomerFile.all()
      .toModelArray()
      .filter(f => f.type_id === FILE_TYPE_ID.PUBLIC && f.status_id === CUSTOMER_FILE_STATUS.VERIFIED)
      .map(f => ({ ...f.ref }));
    const file = last(publicFiles);
    return file;
  }
);

export const customerPublicFilesSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.CustomerFile.all()
      .toModelArray()
      .filter(
        f =>
          f.type_id === FILE_TYPE_ID.PUBLIC &&
          f.status_id !== CUSTOMER_FILE_STATUS.INCORRECT &&
          f.status_id !== CUSTOMER_FILE_STATUS.POOR_QUALITY
      )
      .map(f => ({ ...f.ref }));
  }
);

export const customerChildSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.CustomerChild.all()
      .toModelArray()
      .filter(child => !has(child, 'is_active') || child.is_active)
      .map(child => ({ ...child.ref }));
  }
);

export const customerChildRoleSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.CustomerChildRole.all()
      .toModelArray()
      .map(role => ({ ...role.ref }));
  }
);

export const accountSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const account = session.Account.first();
    if (account !== undefined) {
      return Object.assign({}, account.ref);
    }
    return { amount: 0.0, security_payment_amount: 0.0 };
  }
);

export const officeSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.Office.all()
      .toModelArray()
      .map(office => Object.assign({}, office.ref));
  }
);

export const daySelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    let test = session.Day.all()
      .toModelArray()
      .map(day => Object.assign({}, day.ref));

    console.log(test);

    return session.Day.all()
      .toModelArray()
      .map(day => Object.assign({}, day.ref));
  }
);

export const officeWithParkingSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.Office.all()
      .filter(office => office.status_id === 1 && office.is_parking === 1)
      .toModelArray()
      .map(office => {
        const o = office.ref;
        return Object.assign({}, { label: o.t_name, value: { office: o } });
      });
  }
);

export const officeWithGuestServiceSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.Office.all()
      .filter(office => office.status_id === 1)
      .toModelArray()
      .map(office => {
        const guestsServices = office.services
          .toRefArray()
          .filter(
            service =>
              service.run_type_id === 13 ||
              service.run_type_id === 12 ||
              service.run_type_id === 7 ||
              service.run_type_id === 6 ||
              service.run_type_id === 15
          );
        const o = office.ref;
        return Object.assign(
          {},
          {
            label: o.t_name,
            value: {
              services: guestsServices,
              office: o
            }
          }
        );
      });
  }
);

export const guestList = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.Guest.all()
      .toModelArray()
      .filter(
        guest =>
          !isNil(guest) &&
          !isNil(guest.service_id) &&
          !isNil(guest.service_id.office_id) &&
          !isNil(guest.service_id.office_id.ref)
      )
      .sort((a, b) => {
        const expireA = moment(a.start_at, 'YYYY-MM-DD HH:mm:ss');
        const expireB = moment(b.start_at, 'YYYY-MM-DD HH:mm:ss');
        return expireA.isBefore(expireB) ? 1 : -1;
      })
      .map(guest => {
        const g = guest.ref;
        const location = guest.service_id.office_id.ref;
        return Object.assign({}, g, { location });
      });
  }
);

export const parkingList = createSelector(
  orm,
  dbStateSelector,
  parkingType,
  // eslint-disable-next-line no-shadow
  (session, parkingType) => {
    return session.Parking.all()
      .toModelArray()
      .filter(p => {
        const parking = p.ref;
        const filter = () => {
          if (parkingType) {
            return parking.parkingType === '4590481191001' || parking.parkingType === '3590481191000'; // 'car_entry'
          }
          return parking.parkingType !== '4590481191001' && parking.parkingType !== '3590481191000'; // 'car_entry'
        };
        if (p.service_id && p.service_id.office_id) {
          return filter(); // 'car_entry'
        }
        return false;
      })
      .sort((a, b) => {
        const expireA = moment(a.created_at, 'YYYY-MM-DD HH:mm:ss');
        const expireB = moment(b.created_at, 'YYYY-MM-DD HH:mm:ss');
        return expireA.isBefore(expireB) ? 1 : -1;
      })
      .map(p => {
        const parking = p.ref;
        const location = p.service_id.office_id.ref;
        const request_status = p.request_status;
        // status['12884954000'] = 'Возвращена';
        // status['2804833187000'] = 'Выдан пропуск';
        // status['4285215000'] = 'Создана';
        // status['12884953000'] = 'Принята';
        // status['421575460000'] = 'На территории';
        // status['421575459000'] = 'Отклонена';
        // status['421575453000'] = 'Выполнена';
        // status['4285216000'] = 'Закрыта';

        const housing = [];
        housing['3695397004000'] = 'A';
        housing['3695397005000'] = 'B';
        housing['3695397006000'] = 'C';

        const typeParking = [];
        typeParking['3588462098000'] = 'Гостевая';
        typeParking['3739606434000'] = 'Курьерская';
        typeParking['3590266881000'] = 'A';
        typeParking['3590266893000'] = 'B';
        typeParking['3734201288000'] = 'C';
        // непонятная херня
        typeParking['3588462099000'] = 'neo_geo_claris_parking_rent';
        typeParking['3742207242000'] = 'neo_geo_claris_parking_level_first';
        typeParking['3742207243000'] = 'neo_geo_claris_parking_level_second';

        const orderType = [];
        orderType['421534163000'] = 'Вынос имущества';
        orderType['393629549000'] = 'Внос имущества';
        orderType['393629546000'] = 'Парковочное место';

        return Object.assign(
          {},
          {
            id: parking.id,
            name: parking.name,
            car_brand: parking.car_brand,
            car_number: parking.car_number,
            request_number: parking.requestNumber,
            parking_number: parking.parking_number,

            housing: housing[parking.corpus],
            parking_type: typeParking[parking.parking],
            rejection_reason: parking.rejection_reason,
            request_status: request_status,
            type: orderType[parking.requestType],
            documentType: parking.documentType,
            document_number: parking.document_number,
            //Опечатка в имени поля на бэке
            valueable_data: parking.valueable_data,
            lift: parking.lift,
            comment: parking.comment,
            request_type: orderType[parking.request_type],

            date: parking.date,
            location
          }
        );
      });
  }
);

export const publicProfilesSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const profiles = session.PublicProfiles.all()
      .toModelArray()
      .map(p => ({ ...p.ref }));
    return sortBy(profiles, ['name']);
  }
);

export const customerContractsSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const contracts = session.CustomerContract.first();
    return contracts;
  }
);

export const notificationsSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const notifications = session.Notifications.first();

    return notifications;
  }
);

export const customDaysSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    const days = session.Day.all()
      .toModelArray()
      .map(p => ({ ...p.ref }));
    return days;
  }
);
