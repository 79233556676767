import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { getCustomerCurrent } from '../services/workki/CustomerActions';
import { Loader } from '../shared/ui/components/Loader';
import { PaymentProfileComponent } from '../entities/Profile/PaymentProfile/model/PaymentProfileComponent';

export const PaymentProfilePage = ({ Customer, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerCurrent());
  }, [dispatch]);

  if (!Customer[0]) {
    return null;
  }

  return (
    <PrimaryLayout>
      <main className='main'>{isLoading ? <Loader wrapperType='transparent' /> : <PaymentProfileComponent />}</main>
    </PrimaryLayout>
  );
};
