import { Model } from 'redux-orm';
import * as actions from '../actions';

class ActualNotifications extends Model {
  static reducer(action, notifications) {
    switch (action.type) {
      case actions.requestNotifications.success.toString():
        action.payload.forEach(item => notifications.upsert(item));
        break;
      default:
    }
  }
}

ActualNotifications.modelName = 'ActualNotifications';

export default ActualNotifications;
