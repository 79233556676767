import styled from 'styled-components';
import { connect } from 'react-redux';
import { UploadFile } from '../../icons/core/UploadFile';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { downloadNotificationFile, getNotifications } from '../../../../services/workki/NotificationActions';
import saveAs from 'file-saver';
import moment from 'moment';

const NotificationsBlock = styled.div`
  margin-bottom: 32px;
  padding: 0;
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

const NotificationsBlockTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const NotificationsBlockBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const AllNotificationsBtn = styled.div`
  font-size: 14px;
  color: #8f9295;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
`;

const NotificationsTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #24272a;
`;

const NotificationCard = styled.div`
  box-shadow: 0px 2px 12px 0px #00000014;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: auto;
  padding: 12px 16px;
`;

const NotificationCardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const NotificationCardBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const NotificationCardDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #8f9295;
`;

const NotificationCardDownload = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;

const NotificationCardDownloadText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const NotificationsList = ({ fetchNotifications, downloadNotificationFile }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchNotifications();
        setNotifications(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке уведомлений:', error);
      }
    };

    fetchData();
  }, [fetchNotifications]);

  const history = useHistory();

  const handleFollowLink = () => {
    history.push('/notifications');
  };

  const handleDownload = async (id, filename) => {
    try {
      const response = await downloadNotificationFile(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, filename);
    } catch (error) {
      console.error('Ошибка при скачивании файла:', error);
    }
  };

  return (
    <NotificationsBlock>
      <NotificationsBlockTop>
        <NotificationsTitle>Уведомления</NotificationsTitle>
        <AllNotificationsBtn onClick={handleFollowLink}>Смотреть все</AllNotificationsBtn>
      </NotificationsBlockTop>
      <NotificationsBlockBottom>
        {notifications.map(notification => (
          <NotificationCard key={notification.id}>
            <NotificationCardTitle>{notification.name}</NotificationCardTitle>
            <NotificationCardBottom>
              <NotificationCardDownload onClick={() => handleDownload(notification.id, notification.filename)}>
                <UploadFile width={14} height={14} spacing={'0px'} defaultColor={'#222'} />
                <NotificationCardDownloadText>Скачать PDF</NotificationCardDownloadText>
              </NotificationCardDownload>
              <NotificationCardDate>{moment(notification.created_at).format('DD.MM.YYYY')}</NotificationCardDate>
            </NotificationCardBottom>
          </NotificationCard>
        ))}
      </NotificationsBlockBottom>
    </NotificationsBlock>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchNotifications: () => dispatch(getNotifications()),
  downloadNotificationFile: id => dispatch(downloadNotificationFile(id))
});

export default connect(
  null,
  mapDispatchToProps
)(NotificationsList);
