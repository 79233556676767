import React, { useState } from 'react';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { colors, fontFamilyBase, screenRessolutions } from '../../shared/ui/constants/styleConstants';
import styled from 'styled-components';
import { useResize } from '../../shared/utils/hooks/useResize';
import DashboardStatus from '../../shared/ui/components/Dashboard/DashboardStatus';
import VerticalEllipsis from '../../shared/ui/components/Dashboard/VerticalEllipsis';
import { Hint } from '../../shared/ui/icons/core/Hint';
import CustomSwitch from '../../shared/ui/components/Switch';
import PriceInfoDialog from '../../widgets/DashboardWidgets/PriceInfoDialog';
import DashboardBookingItemModals from './DashboardBookingItemModals';
import cabinet from '../../shared/utils/BackgroundProvider/assets/cabinet.png';
import meetingRoom from '../../shared/utils/BackgroundProvider/assets/meetingRoom.png';
const NameAndDescription = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  gap: 12px;
  box-sizing: border-box;

  & > img {
    margin: 0;
    padding: 0;
    width: 52px;
    height: 52px;
    border-radius: 4px;
    //border: 1px solid red;

    @media screen and (min-width: ${screenRessolutions.desktop}px) {
      width: 64px;
      height: 64px;
    }
  }

  & > svg {
    & > path {
      fill: ${colors.workkiDarkMiddle};
    }
  }
`;

const NameAndDescriptionText = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;

  & > h4 {
    margin: 0;
    padding: 0;
    font-family: ${fontFamilyBase};
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.workkiDarkMain};
  }

  & > p {
    margin: 0;
    padding: 0;
    //styleName: Regular/Short 12;
    font-family: ${fontFamilyBase};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.workkiDarkSecondary};
  }
`;

const DateAndCostBox = styled.div`
  margin: 12px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    flex-direction: row;
    gap: 40px;
  }
`;

const DateAndCost = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${colors.workkiDarkMain};

    & > span {
      display: flex;
      gap: 2px;
      font-weight: 400;
      color: ${colors.workkiDarkMiddle};
    }
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    width: fit-content;
  }
`;

const AutoProlongation = styled.div`
  margin: 17px 0 0 0;
  padding: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  box-siszing: brder-box;

  & > p {
    margin: 0;
    padding: 0;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.workkiDarkMain};
    display: flex;
    gap: 4px;
    width: 100%;
    align-items: flex-end;
  }
`;

const DashboardBookingListItem = ({ item, isMeetingRoom = false, changeAutoProlongation, forFree, ...props }) => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth < screenRessolutions.desktop);
  const handleChangeScreenState = () => {
    const isScreenLaptop = window.innerWidth < screenRessolutions.desktop;
    if (isScreenLaptop !== isLaptop) setIsLaptop(isScreenLaptop);
  };
  useResize(handleChangeScreenState, 0);

  const getDate = (string, schedulableStatus = 1) => {
    if (schedulableStatus !== 1) return null;
    string = string
      .slice(5, 10)
      .split('-')
      .reverse()
      .join('.')
      .slice();
    return string[0] !== '0' ? string : string.slice(1);
  };

  const getTitle = () => {
    if (item.reservation_place.is_unfixed === 1) {
      return 'Нефиксированное рабочее\xa0место';
    }
    if (item.reservation_place.multiplier === 1 && item.reservation_place.is_unfixed === 0) {
      return `Фиксированное рабочее место\xa0${item.reservation_place.name}`;
    }
    return `Кабинет\xa0${item.reservation_place.name}`;
  };

  const getPlace = string => {
    if (string.includes('(')) {
      return string.slice(0, string.indexOf('('));
    }
    return string;
  };

  const getTime = string => {
    const dateArr = string.split(' ');
    return dateArr[1].substring(0, dateArr[1].lastIndexOf(':'));
  };

  const getBookingPeriod = (startTime, expireTime, isMeetingRoom = false) => {
    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря'
    ];

    const startDate = getDate(startTime);
    const expiredDate = getDate(expireTime);

    if (!isMeetingRoom && startDate === expiredDate) {
      let result = startDate.split('.');
      // 0 элемент - день, 1 элемент - месяц
      result[1] = months[parseInt(result[1], 10) - 1];
      return (
        <p>
          <span>Период бронирования</span> {result.join(' ')}
        </p>
      );
    }

    if (!isMeetingRoom && startDate !== expiredDate) {
      let startResult = startDate.split('.');
      let expiredResult = expiredDate.split('.');
      // 0 элемент - день, 1 элемент - месяц
      startResult[1] = months[parseInt(startResult[1], 10) - 1];
      expiredResult[1] = months[parseInt(expiredResult[1], 10) - 1];

      startResult = startResult.join(' ');
      expiredResult = expiredResult.join(' ');

      return (
        <p>
          <span>Период бронирования</span> {startResult} - {expiredResult}
        </p>
      );
    }

    if (isMeetingRoom) {
      let result = startDate.split('.');
      // 0 элемент - день, 1 элемент - месяц
      result[1] = months[parseInt(result[1], 10) - 1];
      result = result.join(' ');

      const startAt = getTime(startTime);
      const expiredAt = getTime(expireTime);

      return (
        <p>
          <span>Период бронирования</span> {result} c {startAt} до {expiredAt}
        </p>
      );
    }
  };

  const getPrice = string => {
    if (string === null) return 'нет данных';
    let num = parseFloat(string);
    let numStr = Number.isInteger(num)
      ? num.toLocaleString('ru-RU')
      : num.toLocaleString('ru-RU', { minimumFractionDigits: 2 });
    numStr = numStr.replace(/[.,]00$/, '');
    numStr = numStr.replace('.', ',').replace(/(.*),(.*)/, '$1, $2');
    let result = numStr + '\u00A0₽';
    return result;
  };

  const [switchState, setSwitchState] = useState(item.subscription ? item.subscription : false);

  const handleInputChange = ({ id }) => evt => {
    if (typeof changeAutoProlongation !== 'function') return;
    changeAutoProlongation(id, evt.target.checked);
    setSwitchState(evt.target.checked);
  };

  const defaultModalState = {
    open: false,
    infoType: '',
    price: null
  };
  const [modalState, setModalState] = useState({ ...defaultModalState });

  const handleCloseModal = () => setModalState({ ...defaultModalState });

  return (
    <>
      {!isMeetingRoom ? (
        <CardShell padding='16px 12px 20px 16px' minHeight='auto' {...props}>
          <NameAndDescription>
            <img src={cabinet} />
            <NameAndDescriptionText>
              <h4>{getTitle()}</h4>
              {/*<p>{getPlace(item.tariff.name)}</p>*/}
              {!isLaptop && (
                <DashboardStatus
                  statusId={item.reservation_place.status_id}
                  startDate={getDate(item.start_at, item.is_schedulable)}
                />
              )}
            </NameAndDescriptionText>
            <VerticalEllipsis
              style={{ minWidth: '24px' }}
              onClick={() => setModalState({ ...modalState, open: true, infoType: 'dashboardActions', price: null })}
            />
          </NameAndDescription>
          <DateAndCostBox>
            <DateAndCost>
              {getBookingPeriod(item.start_at, item.expire_at, false)}
              {isLaptop && (
                <DashboardStatus
                  statusId={item.reservation_place.status_id}
                  startDate={getDate(item.start_at, item.is_schedulable)}
                />
              )}
            </DateAndCost>
            <DateAndCost>
              <p>
                <span>
                  Стоимость бронирования{' '}
                  <Hint
                    onClick={() =>
                      setModalState({
                        open: true,
                        infoType: 'booking',
                        price: getPrice(item.reservation_place.servicePrices[0].price)
                      })
                    }
                  />
                </span>{' '}
                {getPrice(item.price)}
              </p>
            </DateAndCost>
          </DateAndCostBox>
          {item.reservation_place.is_subscribable && (
            <AutoProlongation>
              <CustomSwitch size='small' onChange={handleInputChange(item)} id={item.id} checked={switchState} />
              <p>
                Автопродление{' '}
                <Hint
                  onClick={() =>
                    setModalState({
                      open: true,
                      infoType: 'prolongation',
                      price: getPrice(item.reservation_place.servicePrices[0].price)
                    })
                  }
                />
              </p>
            </AutoProlongation>
          )}
        </CardShell>
      ) : (
        <CardShell padding='16px 12px 20px 16px' minHeight='auto' {...props}>
          <NameAndDescription>
            <img src={meetingRoom} />
            <NameAndDescriptionText>
              <h4>
                {!item.service.name.includes('Ивент-пространство') &&
                  !item.service.name.includes('Конференц-зал') &&
                  'Переговорная'}{' '}
                {item.service.name}
              </h4>
              <p>{getPlace(item.office.t_name)}</p>
              {!isLaptop && (
                <DashboardStatus
                  statusId={item.service.status_id}
                  startDate={getDate(item.start_at, item.service.is_schedulable)}
                />
              )}
            </NameAndDescriptionText>
            <VerticalEllipsis
              minWidth='24px'
              onClick={() => setModalState({ ...modalState, open: true, infoType: 'dashboardActions', price: null })}
            />
          </NameAndDescription>
          <DateAndCostBox>
            <DateAndCost>
              {getBookingPeriod(item.start_at, item.finish_at, true)}
              {isLaptop && (
                <DashboardStatus
                  statusId={item.service.status_id}
                  startDate={getDate(item.start_at, item.service.is_schedulable)}
                />
              )}
            </DateAndCost>
            <DateAndCost>
              <p>
                <span>Стоимость бронирования</span>
                {forFree
                  ? 'По тарифу'
                  : getPrice(
                      item.service.servicePrices.find(item => (item.service_id === item.service_id ? true : false))
                        .price
                    )}
              </p>
            </DateAndCost>
          </DateAndCostBox>
        </CardShell>
      )}
      {modalState.open && <DashboardBookingItemModals state={modalState} handleCloseModal={handleCloseModal} />}
    </>
  );
};

export default DashboardBookingListItem;
