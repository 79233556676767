import { omitBy, isNull, pick } from 'lodash';
import * as actions from '../../store/actions';
import api from './Request';
import { roles } from '../../roles';
import { getRequestStatusActions } from '../../utils/createRequestActions';
import dayjs from 'dayjs';

export const getServices = () => {
  return api.get('/service', { params: { _disableCache: 1, _disablePagination: 1 } }, [
    actions.requestServicesPending,
    actions.requestServicesSuccess,
    actions.requestServicesFailure
  ]);
};
export const getEmployeesServices = () => {
  return api.get('/service/consume/history-childs', { params: { _disableCache: 1, _disablePagination: 1 } }, [
    actions.requestEmployeesServicesPending,
    actions.requestEmployeesServicesSuccess,
    actions.requestEmployeesServicesFailure
  ]);
};

export const getSchedule = (from = null, to = null, service_id = null, office_id = null) => {
  const params = {
    _disableCache: 1,
    _disablePagination: 1,
    ...omitBy({ from, to, service_id, office_id }, isNull)
  };
  return api.get('/service/schedule', { params }, getRequestStatusActions(actions.requestServicesScheduleRequests));
};

export const getServicesFiles = () => {
  const params = { _disableCache: 1, _disablePagination: 1 };
  return api.get('/service/file', { params }, getRequestStatusActions(actions.requestServicesFiles));
};

export const getActiveServiceRequests = () => {
  const params = { _disableCache: 1, _disablePagination: 1 };
  return api.get('/service/request', { params }, getRequestStatusActions(actions.requestServicesRequests));
};

export const getActiveServiceAvailableDays = serviceId => {
  const params = { _disableCache: 1, _disablePagination: 1 };
  return api.get(
    `/service/request/reserved/${serviceId}`,
    { params },
    getRequestStatusActions(actions.getActiveServiceAvailableDaysRequests)
  );
};

export const getServicePaidUnitsCount = (service_id, start_at, finish_at) => {
  const params = { _disableCache: 1 };
  const data = omitBy({ service_id, start_at, finish_at }, isNull);
  return api.post('/service/consume/available', { params, data });
};

export const getLocationPaidUnitsCount = office_id => {
  const params = {
    _disableCache: 1,
    ...omitBy({ office_id }, isNull)
  };

  return api.get('/service/consume/available-count-by-location', { params });
};

export const reserveRoom = (service_id, start_at, finish_at, contract) => {
  const data = { service_id, start_at, finish_at, contract };
  return api.post('/service/meeting-room/booking', { data }, [
    actions.requestServicesReserveRoomPending,
    actions.requestServicesReserveRoomSuccess,
    actions.requestServicesReserveRoomFailure
  ]);
};

export const getWorkplaceAvailablePrice = (id, available_from, cnt) => {
  const params = {
    id,
    available_from,
    cnt,
    _disableCache: 1,
    _disablePagination: 1
  };
  return api.get('/service/location/available', { params });
};

export const changeSubscription = (request_id, hasBeenChosenSubscribe) => {
  const url = hasBeenChosenSubscribe ? 'service/subscription/subscribe' : 'service/subscription/unsubscribe';

  const responseData = { id: request_id, subscription: hasBeenChosenSubscribe };

  return api.post(url, { data: { request_id } }, [
    actions.requestServicesSubscriptionPending,
    () => actions.requestServicesSubscriptionSuccess(responseData),
    actions.requestServicesSubscriptionFailure
  ]);
};

export const getServiceIsActive = (available_from, available_to, service_id = null) => {
  const params = {
    _disableCache: 1,
    _disablePagination: 1,
    ...omitBy({ available_from, available_to, tariff_id: service_id }, isNull)
  };

  return api.get('/service', { params }, getRequestStatusActions(actions.requestServicesIsActive));
};

export const getServiceLocationIsAvailable = (office_id, cnt = 1, available_from = null) => {
  const params = omitBy({ office_id, cnt, available_from, _disablePagination: 1 }, isNull);

  return api.get('/service/location/available', { params }, [
    actions.requestServiceLocationIsAvailable,
    response => actions.requestServiceLocationIsAvailable.success(response, office_id),
    actions.requestServiceLocationIsAvailable.failure
  ]);
};

export const dispatchBuyNow = (service_id, start_at, cnt, price_id, contract, isSubscribed = null, note = null) => {
  const data = { service_id, start_at, cnt, price_id, isSubscribed, note, contract };
  return api.post('/cart/buy-now', { data }, getRequestStatusActions(actions.requestServiceRequestCreate), false);
};

/**
 * @param {Date} targetDate
 * @returns {(function(*, *): (Promise<never>))|(function(*, *): (Promise<never>|null))}
 */
export const getGuestLimit = targetDate => {
  const dateMonthString = dayjs(targetDate).format('YYYY-MM-DD HH:mm:ss');
  return api.get(
    `/service/guest-limit?targetDate=${dateMonthString}`,
    {},
    getRequestStatusActions(actions.requestGuestLimit)
  );
};

export const getGuestsNeoGeo = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/neo-guest',
    {
      permissions,
      params: { _disablePagination: 1 } // TODO remove _disablePagination
    },
    getRequestStatusActions(actions.requestGuests)
  );
};

export const getGuestsCity = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/city',
    {
      permissions,
      params: { _disablePagination: 1 } // TODO remove _disablePagination
    },
    getRequestStatusActions(actions.requestGuests)
  );
};

export const getGuestsParkKultury = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/parkkultury',
    {
      permissions,
      params: { _disablePagination: 1 } // TODO remove _disablePagination
    },
    getRequestStatusActions(actions.requestGuests)
  );
};

export const getGuestKomsomolskaya = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/komsomolskaya',
    {
      permissions,
      params: { _disablePagination: 1 } // TODO remove _disablePagination
    },
    getRequestStatusActions(actions.requestGuests)
  );
};
export const getGuestsTulskaya = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/tulskaya-guest',
    {
      permissions,
      params: { _disablePagination: 1 } // TODO remove _disablePagination
    },
    getRequestStatusActions(actions.requestGuests)
  );
};

export const createGuestsNeoGeo = ({ service_id, start_at, finish_at, firstName, lastName, middleName }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    firstName,
    lastName,
    middleName
  };

  return api.post('/service/consume/scud/neo-guest', { data }, getRequestStatusActions(actions.requestGuestCreate));
};

export const createMultipleGuestsNeoGeo = ({ service_id, start_at, finish_at, guests }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    guests
  };

  return api.post('/service/consume/scud/neo-guest', { data }, getRequestStatusActions(actions.requestGuestCreate));
};

export const createGuestsParkKultury = ({ service_id, start_at, finish_at, firstName, lastName, middleName }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    firstName,
    lastName,
    middleName
  };

  return api.post(
    '/service/consume/scud/parkkultury-guest',
    { data },
    getRequestStatusActions(actions.requestGuestCreate)
  );
};

export const createMultipleGuestsParkKultury = ({ service_id, start_at, finish_at, guests }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    guests
  };

  return api.post(
    '/service/consume/scud/parkkultury-guest',
    { data },
    getRequestStatusActions(actions.requestGuestCreate)
  );
};

export const createGuestsKomsomolskaya = ({ service_id, start_at, finish_at, firstName, lastName, middleName }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    firstName,
    lastName,
    middleName
  };

  return api.post(
    '/service/consume/scud/komsomolskaya-guest',
    { data },
    getRequestStatusActions(actions.requestGuestCreate)
  );
};

export const createMultipleGuestsKomsomolskaya = ({ service_id, start_at, finish_at, guests }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    guests
  };

  return api.post(
    '/service/consume/scud/komsomolskaya-guest',
    { data },
    getRequestStatusActions(actions.requestGuestCreate)
  );
};
export const createMultipleGuestsTulskaya = ({ service_id, start_at, finish_at, guests }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    guests
  };

  return api.post(
    '/service/consume/scud/tulskaya-guest',
    { data },
    getRequestStatusActions(actions.requestGuestCreate)
  );
};

export const createGuestsCity = ({ service_id, start_at, finish_at, firstName, lastName, middleName }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    firstName,
    lastName,
    middleName
  };

  return api.post('/service/consume/scud/city', { data }, getRequestStatusActions(actions.requestGuestCreate));
};

export const createMultipleGuestsCity = ({ service_id, start_at, finish_at, guests }) => {
  const data = {
    service_id,
    start_at,
    finish_at,
    guests
  };

  return api.post('/service/consume/scud/city', { data }, getRequestStatusActions(actions.requestGuestCreate));
};
export const getParkingList = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/neo',
    {
      permissions,
      params: { _disablePagination: 1 } // TODO remove _disablePagination
    },
    getRequestStatusActions(actions.requestParkingList)
  );
};

export const getParkKulturyParkingList = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/park-kultury-parking',
    {
      permissions
    },
    getRequestStatusActions(actions.requestParkingList)
  );
};

export const getTulskayaTMC = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/tulskaya-property',
    {
      permissions
    },
    getRequestStatusActions(actions.requestParkingList)
  );
};

export const getParkKulturyTMC = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get(
    '/service/consume/scud/park-kultury',
    {
      permissions
    },
    getRequestStatusActions(actions.requestParkingList)
  );
};

export const createNeoGeoParking = parkingRequest => {
  return api.post(
    '/service/consume/scud/neo',
    { data: { ...parkingRequest } },
    getRequestStatusActions(actions.requestParkingCreate)
  );
};

export const createParkKulturyParking = parkingRequest => {
  return api.post(
    '/service/consume/scud/park-kultury-parking',
    { data: { ...parkingRequest } },
    getRequestStatusActions(actions.requestParkingCreate)
  );
};
export const createTulskayaTMC = tmcData => {
  return api.post(
    '/service/consume/scud/tulskaya-property',
    { data: { ...tmcData } },
    getRequestStatusActions(actions.requestParkingCreate)
  );
};
export const createParkKulturyTMC = tmcData => {
  return api.post(
    '/service/consume/scud/park-kultury-property',
    { data: { ...tmcData } },
    getRequestStatusActions(actions.requestParkingCreate)
  );
};

export const createItServiceTask = data => {
  const partOfRequestData = pick(data, ['location', 'start_at']);
  return api.post('/service/it-department/booking', { data }, [
    actions.requestItServiceTaskCreate,
    response => actions.requestItServiceTaskCreate.success(response, partOfRequestData),
    actions.requestItServiceTaskCreate.failure
  ]);
};

export const getItServiceTaskList = () => {
  const params = { _disableCache: 1, _disablePagination: 1 };
  return api.get(
    '/service/it-department/booking',
    { params },
    getRequestStatusActions(actions.requestItServiceTaskList)
  );
};
export const getUnfixedWorkplaceTypes = locationId => {
  const params = {
    _disableCache: 1,
    _disablePagination: 1
  };
  return api.get(`/service/unfixed/${locationId}`, { params });
};
