import { BackgroundConfig } from '../../../constants/url.constants';
import background1 from './assets/background1.jpg';
import background2 from './assets/background2.jpg';
import background3 from './assets/background3.jpg';
import background4 from './assets/background4.jpg';

const localImages = {
  'background1.jpg': background1,
  'background2.jpg': background2,
  'background3.jpg': background3,
  'background4.jpg': background4
};

export const createBackgroundService = () => {
  const getInitialBackground = () => {
    const savedBackground = localStorage.getItem('background');
    return savedBackground || 'background1';
  };

  let background = getInitialBackground();

  const getBackground = () => {
    if (BackgroundConfig.imageSource === 'server') {
      return `${BackgroundConfig.serverImageUrl}${background}`;
    } else {
      return localImages[background] || localImages['background1.jpg'];
    }
  };

  const changeBackground = newBackground => {
    if (localImages[newBackground] || BackgroundConfig.imageSource === 'server') {
      background = newBackground;
      localStorage.setItem('background', newBackground);
    } else {
      console.warn(`Background ${newBackground} does not exist.`);
    }
  };

  return {
    getBackground,
    changeBackground,
    localImages
  };
};
