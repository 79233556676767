import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../shared/ui/constants/styleConstants';
import { TopBlock } from '../../shared/ui/components/Dialog';
import FormControl from '@mui/material/FormControl';
import CustomDatePicker from '../../shared/ui/components/CustomDatePicker';
import { TabsComponent } from '../../shared/ui/components/Tabs/Tabs';
import Counter from '../../shared/ui/components/Counter';
import CustomSwitch from '../../shared/ui/components/Switch';
import { StepButton } from '../../shared/ui/components/StepButton';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import withBookingWidget from '../../shared/utils/hocs/withBookingWidget';
import { PRICE_COUNT_TYPE, TariffsEnum } from '../../constants';
import moment from 'moment-timezone';
import Portal, { createContainer } from '../../hocs/Portal';
import { Loader } from '../../shared/ui/components/Loader';
import { removeZeros } from '../../shared/utils/serviceUtils/validationUtils';
import { StepLabel } from '../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { customDaysSelector, officeSelector } from '../../store/selectors/DefaultSelectors';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { serviceRequestWithWorkplacesSelector } from '../../store/selectors/ServicesSelector';
import { InfoAlert } from '../../shared/ui/components/Profile/InfoAlert';
import { isDateBusy } from '../../shared/utils/helperFunctions/heplerFunctions';
import { getActiveServiceAvailableDays } from '../../services/workki/ServicesActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const EntitiesTypesEnum = {
  CABINET: 0,
  FIXED_WORKPLACE: 1,
  UNFIXED_WORKPLACE: 2
};

const TimerBlock = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
  color: ${colors.workkiRedFill};
  margin-top: 4px;
  margin-bottom: 16px;
`;

const TariffInfoBlock = styled.div`
  & > p {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    margin: 8px 0;
  }
`;

const CounterBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 18px 0;
  align-items: center;
  justify-content: space-between;
  & > p {
    width: 135px;
    color: ${colors.workkiDarkSecondary};
    font-size: 14px;
    font-weight: 300;
  }
`;

export const BookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: start;
  padding: 24px 16px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

const WorkplaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 16px;
  & > p:first-of-type {
    font-weight: 400;
  }
  & > p {
    font-weight: 300;
    margin: 0;
  }
  font-size: 14px;
  text-align: start;
`;
const PriceInfo = styled.div`
  display: flex;
  flex-direction: ${props => (props.type === 'price' ? 'column' : 'row')};
  justify-content: flex-start;
  margin-bottom: 16px;
  & > p:first-of-type {
    font-weight: 300;
    margin-right: ${props => (props.type === 'price' ? '0' : '4px')};
  }
  & > p {
    font-weight: 400;
    margin: 0;
  }
  font-size: 14px;
  text-align: start;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > p {
    color: ${props => (props.disabled ? '#8e8e8e' : 'inherit')};
    margin: 0;
    font-size: 14px;
    font-weight: 300;
  }
`;

const BookingWidget = props => {
  const { amount } = props.account;
  const {
    dialogData,
    handleSubmit,
    loadingStatus,
    customer,
    pickedDate,
    setPickedDate,
    currentLocationValue,
    currentSubLocationValue,
    handlePrevStep,
    unfixedWorkplaces,
    getUnfixedWorkplaceTypes
  } = props;
  const LOADER_CONTAINER_ID = 'loader-container';
  const [countOfUnits, setCountOfUnits] = useState(1);
  const [unitType, setUnitType] = useState('');
  const [contract, setContract] = useState({});
  const [isMount, setIsMount] = useState(false);
  const [bookingProlongation, setBookingProlongation] = useState(false);
  const [tariffTabs, setTariffTabs] = useState([{ label: 'Месяц', subtitle: '' }]);
  const [tariffTabValue, setTariffTabValue] = useState(0);
  const [availableUnfixedWorkplaces, setAvailableUnfixedWorkplaces] = useState([]);
  const [currentUnfixedWorkplace, setCurrentUnfixedWorkplace] = useState({});
  const [fullPrice, setFullPrice] = useState(0);
  const [workplaceType, setWorkplaceType] = useState(null);
  const customDays = useSelector(customDaysSelector);
  const currentTariff = useMemo(() => {
    const findTariff = tariffTabs.find((tab, index) => index === tariffTabValue);
    return findTariff ? findTariff.id : null;
  }, [tariffTabs, tariffTabValue]);
  const defineActualPrice = workplace => {
    return workplace.servicePrices.find(price => price.is_active === 1);
  };
  const bookingInfo = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: dayjs().subtract(365, 'days'),
      selectorType: 'standard'
    })
  );
  const dispatch = useDispatch();
  const getAvailableDays = id => dispatch(getActiveServiceAvailableDays(id));
  const [busyDates, setBusyDates] = useState([]);
  const [isBusyDatesLoading, setIsBusyDatesLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (dialogData.tariff.name.includes('Коворкинг')) {
      setWorkplaceType(EntitiesTypesEnum.FIXED_WORKPLACE);
    } else if (!!dialogData.is_unfixed) {
      setWorkplaceType(EntitiesTypesEnum.UNFIXED_WORKPLACE);
    } else {
      setWorkplaceType(EntitiesTypesEnum.CABINET);
    }
  }, []);
  const handleTabChange = (event, newValue) => {
    const currentTab = tariffTabs.find((tab, index) => index === newValue);
    setTariffTabValue(newValue);
    // setFullPrice(displayActivePrice(pickedWorkplace.servicePrices));
    setFullPrice(parseInt(currentTab.subtitle.replaceAll(/\s/g, '')));
    if (!!dialogData.is_unfixed) {
      const pickedWorkplace = availableUnfixedWorkplaces.find(workplace => workplace.id === currentTab.id);
      setCurrentUnfixedWorkplace(pickedWorkplace);
    }
  };
  const handleSwitchChange = event => {
    setBookingProlongation(event.target.checked);
  };
  useEffect(() => {
    props.getContracts();
    if (customer.contracts && customer.contracts.length > 0) {
      setContract(customer.contracts);
    }
    if (dialogData) {
      getUnfixedWorkplaceTypes(dialogData.office_id)
        .then(response => {
          const typeInstances = response.data;
          const filteredWorkplaces = unfixedWorkplaces.reduce((acc, workplace) => {
            typeInstances.forEach(typeInstance => {
              if (workplace.id === typeInstance.id) {
                acc.push({ ...workplace, type: typeInstance.type });
              }
            });
            return acc;
          }, []);
          setAvailableUnfixedWorkplaces(filteredWorkplaces);
          setCurrentUnfixedWorkplace(filteredWorkplaces.find(unfixedWorkplace => unfixedWorkplace.type === 'month'));
        })
        .catch(error => {});
      setFullPrice(displayActivePrice(dialogData.servicePrices));
      if (dialogData.is_unfixed === 0) {
        setUnitType('month');
      }
    }

    setContract(getSelectedContract);
    setIsMount(true);
    createContainer({ id: LOADER_CONTAINER_ID });
    const twoWeeksObj = dialogData.servicePrices.find(
      item => !!item.is_active && item.cnt_type_id === PRICE_COUNT_TYPE.BY_TWO_WEEKS
    );
    if (twoWeeksObj) {
      setTariffTabs(prevState => {
        return [
          ...prevState,
          { label: 'Две недели', subtitle: removeZeros(+twoWeeksObj.price) + ' ₽', id: TariffsEnum.TWO_WEEKS.id }
        ];
      });
    }
  }, []);

  // Подготавливаем значения для табов (Для нефиксированных рабочих мест)
  useEffect(() => {
    if (dialogData && dialogData.is_unfixed === 1) {
      if (currentUnfixedWorkplace) {
        setUnitType(currentUnfixedWorkplace.type);
      }
    }
  }, [currentUnfixedWorkplace, availableUnfixedWorkplaces]);

  useEffect(() => {
    if (loadingStatus === 'success' || loadingStatus === 'error') {
      handleSubmit(loadingStatus);
    }
  }, [loadingStatus]);
  const getContractsForSelect = () => {
    if (!contract) return [];

    const contractsForSelect = {
      id: contract.id,
      number: contract.id,
      start_at: contract.date,
      type: contract.type,
      label: `№ ${contract.number} от ${moment(contract.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}`
    };

    return contractsForSelect;
  };
  const getSelectedContract = () => {
    if (contract) {
      return contract;
    } else {
      const { id, type } = getContractsForSelect();

      return {
        contract_id: id,
        type: type
      };
    }
  };
  const displayWorkplaceTitle = (name, tariffName) => {
    if (tariffName.includes('Коворкинг')) {
      return 'Фиксированное рабочее место' + ' ' + name;
    } else return name + ' ' + tariffName;
  };
  const displayDateTitle = (pickedDate, numberOfMonths = 1) => {
    if (!pickedDate) {
      return '';
    }
    const monthAttributes = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря'
    ];
    const monthNumber = pickedDate.month(); // Получаем номер месяца
    const formatedPickedDate = pickedDate.date().toString() + ' ' + monthAttributes[monthNumber];
    if (currentTariff === TariffsEnum.DAY.id || currentTariff === TariffsEnum.TEST_DAY.id) {
      return formatedPickedDate;
    } else if (currentTariff === TariffsEnum.TWO_WEEKS.id) {
      const endOfMonth = pickedDate.clone().endOf('month');
      const dateAfter14Days = pickedDate.clone().add(14, 'days');
      // Проверяем, сколько дней осталось до конца месяца
      const daysUntilEndOfMonth = endOfMonth.diff(pickedDate, 'days');
      // Если до конца месяца осталось меньше 14 дней, используем последний день месяца
      const finalDate = daysUntilEndOfMonth < 14 ? endOfMonth : dateAfter14Days;

      return (
        'с ' + formatedPickedDate + ' по ' + finalDate.date().toString() + ' ' + monthAttributes[finalDate.month()]
      );
    } else {
      // Если передан 1 месяц, возвращаем дату последнего дня месяца
      if (numberOfMonths === 1) {
        const lastDayOfMonth = pickedDate.clone().endOf('month');
        if (pickedDate.date().toString() === lastDayOfMonth.date().toString()) {
          return pickedDate.date().toString() + ' ' + monthAttributes[monthNumber];
        }
        return (
          'с ' + formatedPickedDate + ' по ' + lastDayOfMonth.date().toString() + ' ' + monthAttributes[monthNumber]
        );
      } else {
        // Определяем последнюю дату в зависимости от количества месяцев
        const endDate = pickedDate
          .clone()
          .add(numberOfMonths - 1, 'month')
          .endOf('month');

        return 'с ' + formatedPickedDate + ' по ' + endDate.date().toString() + ' ' + monthAttributes[endDate.month()];
      }
    }
  };
  const displayActivePrice = pricesInfo => {
    const activePriceInfo = pricesInfo.find(price => price.is_active === 1);
    return activePriceInfo.price;
  };
  useEffect(() => {
    if (!fullPrice || !dialogData) {
      return;
    }

    if (!!dialogData.is_unfixed) {
      getUnfixedWorkplaceTypes(dialogData.office_id)
        .then(response => {
          const typeInstances = response.data;
          const filteredWorkplaces = unfixedWorkplaces.reduce((acc, workplace) => {
            typeInstances.forEach(typeInstance => {
              if (workplace.id === typeInstance.id) {
                acc.push({ ...workplace, type: typeInstance.type });
              }
            });
            return acc;
          }, []);
          const unfixedWorkplacesTabs = filteredWorkplaces.reduce((acc, workplace) => {
            const activePriceData = defineActualPrice(workplace);
            let tariffLabel = '';
            let tariffId = 0;
            if (workplace.name.includes('(день)')) {
              tariffLabel = 'День';
              tariffId = TariffsEnum.DAY.id;
            } else if (workplace.name.includes('(тестовый день)')) {
              tariffId = TariffsEnum.TEST_DAY.id;
              tariffLabel = 'Тестовый день';
            } else {
              tariffId = TariffsEnum.MONTH.id;
              tariffLabel = 'Месяц';
            }
            const activePrice = workplace.servicePrices.find(item => !!item.is_active);
            let priceId = null;
            if (activePrice) {
              priceId = activePrice.id;
            }
            acc.push({
              label: tariffLabel,
              subtitle: removeZeros(+activePriceData.price) + ' ₽',
              id: tariffId,
              priceId: priceId
            });
            return acc;
          }, []);
          setTariffTabs(unfixedWorkplacesTabs);
        })
        .catch(error => {});
    } else {
      setTariffTabs(
        dialogData.servicePrices
          .filter(item => !!item.is_active)
          .map(item => {
            const tariff = Object.values(TariffsEnum).find(tariff => item.cnt_type_id === tariff.CNT_TYPE);
            return {
              label: tariff.label,
              subtitle: removeZeros(+item.price) + ' ₽',
              id: tariff.id
            };
          })
      );
    }
  }, [fullPrice]);

  useEffect(() => {
    if (dialogData && dialogData.id) {
      setIsBusyDatesLoading(true);
      getAvailableDays(dialogData.id)
        .then(response => {
          const busyDatesFromResponse = response.data.map(item => ({
            start: dayjs(item.start_at),
            end: dayjs(item.expire_at)
          }));
          setBusyDates(busyDatesFromResponse);
          setIsBusyDatesLoading(false);
        })
        .catch(error => {
          console.error('Error fetching busy dates:', error);
          setIsBusyDatesLoading(false);
        });
    }
  }, [dialogData]);

  /** Проверяет является ли дата недоступной в зависимости от текущего тарифа, праздников и других броней */
  const disableUnavailableDay = day => {
    day = dayjs(day);
    let reservationData = bookingInfo.length ? bookingInfo : [];

    if (busyDates.length > 0) {
      if (busyDates.some(({ start, end }) => day.isBetween(start, end, 'day', '[]'))) {
        return true;
      }
    }

    if (!!dialogData.is_unfixed) {
      return false;
    }

    if (isDateBusy(day, reservationData, dialogData.id)) {
      return true;
    }

    if (workplaceType === EntitiesTypesEnum.CABINET) {
      return false;
    }

    if (dialogData.tariff.name.includes('Коворкинг City')) {
      return false;
    }

    const data = customDays.filter(x => {
      return x.date.toString() === day.format('DD-MM');
    });
    if (data.length > 0) {
      return data[0].status === 0;
    } else {
      return day.day() === 0 || day.day() === 6;
    }
  };

  /** Проверяет возможность продления выбранного тарифа на следующий период */
  function isNextPeriodTariffAvailable(day, tariff) {
    day = dayjs(day); // Преобразуем входную дату с помощью dayjs

    let startDate = day;
    let endDate;

    switch (tariff) {
      case TariffsEnum.MONTH.id:
        // Устанавливаем конец месяца
        startDate = day.add(1, 'month').startOf('month');
        endDate = startDate.endOf('month');
        break;

      case TariffsEnum.TWO_WEEKS.id:
        const endOfMonth = day.endOf('month');
        const twoWeeksLater = day.add(14, 'days');

        // Определяем конечную дату текущей брони
        if (twoWeeksLater.isAfter(endOfMonth)) {
          endDate = endOfMonth; // Если 14 дней больше чем до конца месяца, устанавливаем конец месяца
        } else {
          endDate = twoWeeksLater; // Иначе устанавливаем дату через 14 дней
        }

        // Устанавливаем начальную дату следующего периода
        startDate = endDate.add(1, 'days'); // Начинаем следующий период на день позже

        // Определяем конечную дату следующего периода
        const daysUntilEndOfMonth = startDate.endOf('month').diff(startDate, 'days');

        if (daysUntilEndOfMonth < 14) {
          endDate = startDate.add(daysUntilEndOfMonth, 'days'); // Устанавливаем конец следующего периода до конца месяца
        } else {
          endDate = startDate.add(13, 'days'); // Конец следующего периода через 13 дней (тк 1 текущий день включен в период брони)
        }
        break;

      case TariffsEnum.DAY.id:
        // Проверяем доступность следующего дня, если он в этом месяце
        endDate = day.add(1, 'day');
        break;
      case TariffsEnum.TEST_DAY.id:
        // Проверяем доступность следующего дня, если он в этом месяце
        endDate = day.add(1, 'day');
        break;
      default:
        console.error('Unknown tariff');
        return false;
    }

    // Проверяем доступность всех дней в заданном диапазоне
    let currentDate = startDate;
    let reservationData = bookingInfo.length ? bookingInfo : [];
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      if (
        isDateBusy(currentDate, reservationData, dialogData.id) ||
        busyDates.some(({ start, end }) => currentDate.isBetween(start, end, 'day', '[]'))
      ) {
        return false; // Если хотя бы один день недоступен, возвращаем false
      }
      currentDate = currentDate.add(1, 'day'); // Переходим к следующему дню
    }
    return true; // Все дни доступны
  }

  const isTariffAvailable = () => {
    let day = dayjs(pickedDate); // Преобразуем текущую дату с помощью dayjs

    let startDate = day;
    let endDate;

    switch (currentTariff) {
      case TariffsEnum.MONTH.id:
        endDate = startDate.endOf('month');
        break;

      case TariffsEnum.TWO_WEEKS.id:
        // Проверяем, если осталось меньше 14 дней до конца месяца
        const endOfMonth = day.endOf('month');
        const twoWeeksLater = day.add(14, 'days');

        if (twoWeeksLater.isAfter(endOfMonth)) {
          endDate = endOfMonth; // Если 14 дней больше чем до конца месяца, устанавливаем конец месяца
        } else {
          endDate = twoWeeksLater; // Иначе устанавливаем дату через 14 дней
        }
        break;

      case TariffsEnum.DAY.id:
        endDate = day;
        break;
      case TariffsEnum.TEST_DAY.id:
        endDate = day;
        break;
      default:
        console.error('Unknown tariff');
        return false;
    }

    // Проверяем доступность всех дней в заданном диапазоне
    let currentDate = startDate;
    let reservationData = bookingInfo.length ? bookingInfo : [];
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      if (
        isDateBusy(currentDate, reservationData, dialogData.id) ||
        busyDates.some(({ start, end }) => currentDate.isBetween(start, end, 'day', '[]'))
      ) {
        return false; // Если хотя бы один день недоступен, то и тариф недоступен
      }
      currentDate = currentDate.add(1, 'day'); // Переходим к следующему дню
    }
    return true; // Тариф доступен
  };

  useEffect(() => {
    if (!isNextPeriodTariffAvailable(pickedDate, currentTariff)) {
      setBookingProlongation(false);
    }
  }, [pickedDate]);

  const calculatePricePerPeriod = (monthsQuantity, startReservationDate) => {
    if (currentTariff === TariffsEnum.DAY.id || currentTariff === TariffsEnum.TEST_DAY.id) {
      return fullPrice;
    }

    let formatedPrice = 0;
    const year = startReservationDate.year();
    const month = startReservationDate.month(); // Оставляем месяц без +1, так как dayjs использует 0-индексацию

    // Получаем количество дней в текущем месяце
    const daysInMonth = dayjs(`${year}-${month + 1}`).daysInMonth();

    const daysArray = [];

    // Заполняем массив днями месяца
    for (let day = 1; day <= daysInMonth; day++) {
      daysArray.push(dayjs(`${year}-${month + 1}-${day}`).format('YYYY-MM-DD'));
    }

    // Фильтруем рабочие дни
    // Закомментировал для будущего учета праздничных дней
    // const workingDaysArray = daysArray.filter(day => !(dayjs(day).day() === 0 || dayjs(day).day() === 6));
    // const daysQuantity = workingDaysArray.length;

    // Вычисляем цену за день
    let pricePerDay = fullPrice / daysInMonth;

    if (currentTariff === TariffsEnum.MONTH.id) {
      // Вычисляем количество рабочих дней с начала резервации
      const fullDaysQuantity = daysArray.filter(day => {
        const currentDay = dayjs(day);
        return currentDay.isAfter(startReservationDate) || currentDay.isSame(startReservationDate, 'day');
      }).length;

      formatedPrice = parseFloat((pricePerDay * fullDaysQuantity).toFixed(2));

      if (monthsQuantity > 1) {
        formatedPrice += +fullPrice * (monthsQuantity - 1);
      }
    } else if (currentTariff === TariffsEnum.TWO_WEEKS.id) {
      pricePerDay = +fullPrice / 14;
      const fullDaysQuantity = daysArray.filter(day => {
        const currentDay = dayjs(day);
        return currentDay.isAfter(startReservationDate) || currentDay.isSame(startReservationDate, 'day');
      }).length;
      formatedPrice = parseFloat((pricePerDay * (fullDaysQuantity > 14 ? 14 : fullDaysQuantity)).toFixed(2));
    } else return fullPrice * countOfUnits;

    return formatedPrice;
  };
  const pricePerPeriod = Math.round(calculatePricePerPeriod(countOfUnits, pickedDate) * 100) / 100;
  const handleBookWorkplace = () => {
    const { handleBuyNow, isGuest, history, customer } = props;

    if (isGuest) {
      history.push({
        pathname: '/prefill-profile',
        state: {
          message: 'Пожалуйста, заполните профиль для пользования полным функционалом личного кабинета.'
        }
      });
      return;
    }

    const place = dialogData && dialogData.is_unfixed === 0 ? dialogData : currentUnfixedWorkplace;
    const start_at = pickedDate.format('YYYY-MM-DD HH:mm:ss');
    const contract = getSelectedContract();
    const currentTab = tariffTabs.find((tab, index) => index === tariffTabValue);
    const getCntTypeById = id => {
      const tariff = Object.values(TariffsEnum).find(t => t.id === id);
      return tariff ? tariff.CNT_TYPE : null; // Возвращаем CNT_TYPE или null, если не найдено
    };

    let priceId = null;
    if (fullPrice && place) {
      const price = place.servicePrices.find(place => place.cnt_type_id === getCntTypeById(currentTab.id));
      priceId = price.id;
    } else if (currentTab.priceId) {
      priceId = currentTab.priceId;
    }

    handleBuyNow(
      dialogData.id,
      start_at,
      countOfUnits,
      priceId,
      contract,
      bookingProlongation,
      dialogData.office_id,
      customer && customer.is_new
    );
  };

  const handleIncrement = () => {
    setCountOfUnits(prevState => prevState + 1);
  };

  const handleDecrement = () => {
    setCountOfUnits(prevState => {
      if (prevState > 1) {
        return prevState - 1;
      }
      return prevState;
    });
  };

  const renderAlert = useCallback(() => {
    const [isUnavailableCurrentPeriod, isUnavailableNextPeriod] = [
      !isTariffAvailable(),
      !isNextPeriodTariffAvailable(pickedDate, currentTariff)
    ];

    if (isBusyDatesLoading) {
      return null; // Пока данные загружаются, не показываем предупреждение
    }

    // такая структура написана чтобы в будущем можно было вставить более гибкие интервалы
    const renderSentences = [];
    if (isUnavailableCurrentPeriod) {
      renderSentences.push('в текущем');
    }
    if (isUnavailableNextPeriod) {
      renderSentences.push('в следующем');
    }

    if (!renderSentences.length) {
      return null;
    } else if (renderSentences.length === 1) {
      return <InfoAlert title={`Недоступно для брони ${renderSentences[0]} периоде`} type={'warning'} />;
    }

    return <InfoAlert title={`Недоступно для брони ${renderSentences.join(' и ')} периоде`} type={'warning'} />;
  }, [isBusyDatesLoading, isTariffAvailable(), isNextPeriodTariffAvailable(pickedDate, currentTariff)]);

  return (
    <>
      <BookingWrapper>
        <TopBlock direction={'flex-start'} spacing={'16px'}>
          <StepLabel reservation={true} onClick={handlePrevStep}>
            <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} />
            <p>Оплата бронирования</p>
          </StepLabel>
        </TopBlock>
        {/*<TimerBlock>Оплатите в течение 14:45</TimerBlock>*/}
        <FormControl fullWidth={true}>
          <CustomDatePicker
            pickedDate={pickedDate}
            setPickedDate={value => setPickedDate(value)}
            isDayDisabled={disableUnavailableDay}
          />
        </FormControl>
        <TariffInfoBlock>
          <p>Выбранный тариф:</p>
          <TabsComponent
            tabsContainerStyle={{ width: dialogData && dialogData.is_unfixed === 0 ? '130px' : '100%', height: '54px' }}
            tabStyle={{ height: '46px', width: '130px' }}
            tabValue={tariffTabValue}
            handleTabChange={handleTabChange}
            tabs={tariffTabs}
          />
          {tariffTabValue === TariffsEnum.MONTH.id ? (
            <CounterBlockWrapper>
              <p>Количество месяцев бронирования</p>
              <Counter
                count={countOfUnits}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                type={unitType}
              />
            </CounterBlockWrapper>
          ) : (
            <CounterBlockWrapper />
          )}
          {renderAlert()}
          {tariffTabValue !== TariffsEnum.TEST_DAY.id && (
            <SwitchWrapper disabled={!isNextPeriodTariffAvailable(pickedDate, currentTariff)}>
              <CustomSwitch
                disabled={!isNextPeriodTariffAvailable(pickedDate, currentTariff)}
                size='small'
                spacing='6px'
                checked={bookingProlongation}
                onChange={handleSwitchChange}
              />
              <p>Подключить автопродление</p>
            </SwitchWrapper>
          )}
        </TariffInfoBlock>
      </BookingWrapper>

      <BookingWrapper>
        {dialogData && (
          <WorkplaceInfo>
            <p>{displayWorkplaceTitle(dialogData.name, dialogData.tariff.name)}</p>
            <p>{currentLocationValue + ' ' + currentSubLocationValue}</p>
            <p>{displayDateTitle(pickedDate, countOfUnits)}</p>
            {bookingProlongation && <p>Подключено автопродление</p>}
          </WorkplaceInfo>
        )}
        <PriceInfo type={'price'}>
          <p>К оплате:</p>
          <p>{pricePerPeriod} ₽</p>
        </PriceInfo>
        {Number(pricePerPeriod) > Number(amount) && (
          <PriceInfo type={'checkoutDifference'}>
            <p>Необходимо внести на баланс: </p>
            <p>{Math.round((pricePerPeriod - amount) * 100) / 100} ₽</p>
          </PriceInfo>
        )}
        <FormControl fullWidth={true}>
          <StepButton
            disabled={!isTariffAvailable()}
            filledType={'red'}
            type={'filled'}
            iconColor={'white'}
            title={Number(pricePerPeriod) > Number(amount) ? 'Пополнить баланс' : 'Забронировать'}
            iconType={'arrow'}
            handleAction={event => {
              if (Number(pricePerPeriod) > Number(amount)) {
                history.push('/payments', {
                  addToBalance: `${Math.round((pricePerPeriod - amount) * 100) / 100}`
                });
              } else {
                handleBookWorkplace(event);
              }
            }}
          />
        </FormControl>
      </BookingWrapper>
      {isMount && loadingStatus === 'loading' && (
        <Portal id={LOADER_CONTAINER_ID}>
          <Loader />
        </Portal>
      )}
    </>
  );
};

export default withBookingWidget(BookingWidget);
