import * as actions from '../../store/actions';
import { getRequestStatusActions } from '../../utils/createRequestActions';
import api from './Request';

export const changeLegalEntityContacts = data => {
  return api.post(
    '/customer/info/company/contacts/update',
    { data },
    getRequestStatusActions(actions.requestLegalEntityContactsChange)
  );
};

export const changeLegalEntityAddress = data => {
  return api.post(
    '/customer/info/company/address/update',
    { data },
    getRequestStatusActions(actions.requestLegalEntityAddressChange)
  );
};
