import React from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';
import { PhoneMask } from '../../../shared/ui/icons/core/PhoneMask';
import { CheckboxComponent } from '../../../shared/ui/components/Сheckbox';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

const RegistrationFailedStep = ({
  entranceFormClass,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  errors,
  handlePolicyChange,
  handleFailedRegisterSubmit,
  policyChecked,
  formData,
  filled,
  isFocused,
  isPending,
  disableButton
}) => {
  return (
    <div>
      <div className='authorization__caption'>
        <p>Не можете зарегистрироваться?</p>
        <p>Оставьте свои контактные данные, мы с вами свяжемся, чтобы решить проблему.</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={handleFailedRegisterSubmit}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.email.registrationFailed}
          type='email'
          name='email'
          id='outlined-helperText'
          label='E-mail'
          variant='filled'
          value={formData.email.registrationFailed}
          onFocus={() => handleFocus('email', 'registrationFailed')}
          onBlur={() => handleBlur('email', 'registrationFailed')}
          onChange={handleInputChange}
          disabled={isPending}
          helperText={errors.email.registrationFailed ? errors.email.registrationFailed : ''}
          endAdornmentProps={{
            filled: filled.email.registrationFailed,
            isFocused: isFocused.email.registrationFailed,
            handleEraseValue: name => handleEraseValue(name, 'registrationFailed')
          }}
          autoFocus={true}
        />
        <CustomTextField
          error={errors.firstName}
          id='outlined-helperText'
          label='Имя'
          name='firstName'
          helperText={errors.firstName ? errors.firstName : ''}
          variant='filled'
          type='text'
          value={formData.firstName}
          onChange={handleInputChange}
          disabled={isPending}
          onFocus={() => handleFocus('firstName')}
          onBlur={() => handleBlur('firstName')}
          endAdornmentProps={{
            filled: filled.firstName,
            isFocused: isFocused.firstName,
            handleEraseValue: handleEraseValue
          }}
          autoFocus={false}
        />
        <CustomTextField
          error={errors.phone}
          id='outlined-helperText'
          label='Телефон'
          name='phone'
          helperText={errors.phone ? errors.phone : ''}
          variant='filled'
          type='tel'
          value={formData.phone}
          onChange={handleInputChange}
          disabled={isPending}
          placeholder={'999 999-99-99'}
          onFocus={() => handleFocus('phone')}
          onBlur={() => handleBlur('phone')}
          endAdornmentProps={{
            filled: filled.phone,
            isFocused: isFocused.phone,
            handleEraseValue: handleEraseValue
          }}
          startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
          autoFocus={false}
        />
        <div className='authorization__footer__policy-wrapper'>
          <CheckboxComponent
            checked={policyChecked}
            onChange={handlePolicyChange}
            name='policy'
            checkedIcon={<CheckBoxRoundedIcon />}
          />
          <p className='authorization__footer__registration-policy'>
            Согласен с&#160;
            <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
              договором публичной оферты
            </a>{' '}
            и&#160;
            <a href=' https://workki.co/upload/iblock/f5f/Personal_data.pdf' target='_blank'>
              правилами обработки персональных данных
            </a>
          </p>
        </div>
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Отправить
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default RegistrationFailedStep;
